<template>
  <aside
    id="sidebar"
    class="fixed top-0 left-0 bottom-0 z-40 h-screen transition-all duration-300"
    :class="{
      absolute: !store.displayed && isMobile(),
      'w-16': !store.displayed || isMobile(),
      'w-64': store.displayed && !isMobile(),
    }"
    aria-label="Sidebar"
  >
    <div
      class="flex flex-col h-full overflow-auto box-border transition-all duration-300"
      :class="[
        {
          'bg-surface-0 dark:bg-surface-900':
            !isMobile() || (store.displayed && isMobile()),
          'no-scrollbar': !store.displayed || isMobile(),
        },
      ]"
    >
      <!-- Header -->
      <div
        class="flex items-center px-2 py-[19px] h-[69px] border-b border-surface-200 gradient-border-2 dark:border-surface-700 transition-all duration-300"
        :class="{
          'sm:justify-between ': store.displayed,
          'border-none justify-center': isMobile() && store.displayed,
        }"
      >
        <Button
          type="button"
          :text="!isMobile()"
          icon="pi pi-align-left"
          class="text-primary-400 dark:text-surface-400 ml-[5px]"
          @click="toggleSidebar"
          aria-haspopup="true"
          aria-controls="overlay_menu"
        />

        <a
          href="#"
          class="items-center"
          :class="{
            flex: store.displayed,
            hidden: !store.displayed || isMobile(),
          }"
        >
          <img
            v-if="store.displayed && !isMobile()"
            src="@/assets/images/logo-dark.png"
            class="me-3 sm:block mt-2 w-24 dark:opacity-50"
            alt="Opus127 Logo"
          />
        </a>
      </div>

      <!-- Menu Section -->
      <div
        class="flex p-3 ml-1 transition-opacity duration-300"
        :class="{ hidden: !store.displayed && isMobile() }"
      >
        <h2
          class="font-bold text-surface-600 dark:text-surface-300 text-xs uppercase"
        >
          Menu
        </h2>
      </div>

      <div
        class="flex flex-col px-3 py-2"
        :class="{
          'justify-center': !store.displayed || isMobile(),
          hidden: !store.displayed && isMobile(),
        }"
      >
        <ul class="w-full">
          <!-- Dashboard -->
          <SideBarItem
            to="/"
            label="Dashboard"
            tooltip="Dashboard"
            :active="$route.path === '/'"
            :is-mobile="isMobile()"
            :displayed="store.displayed"
          >
            <template #icon>
              <svg
                class="flex-shrink-0 w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 22 22 "
              >
                <path
                  d="M1.5 9.93841C1.5 8.71422 2.06058 7.55744 3.02142 6.79888L8.52142 2.45677C9.97466 1.30948 12.0253 1.30948 13.4786 2.45677L18.9786 6.79888C19.9394 7.55744 20.5 8.71422 20.5 9.93841V16.5C20.5 18.7091 18.7091 20.5 16.5 20.5H15C14.4477 20.5 14 20.0523 14 19.5V16.5C14 15.3954 13.1046 14.5 12 14.5H10C8.89543 14.5 8 15.3954 8 16.5V19.5C8 20.0523 7.55228 20.5 7 20.5H5.5C3.29086 20.5 1.5 18.7091 1.5 16.5L1.5 9.93841Z"
                  stroke=""
                  stroke-width="1.5"
                />
              </svg>
            </template>
          </SideBarItem>

          <!-- Analises -->
          <SideBarItem
            to="/analises"
            label="Análises"
            tooltip="Análises"
            :active="$route.path.startsWith('/analises')"
            :is-mobile="isMobile()"
            :displayed="store.displayed"
            :class="{
              'pointer-events-none opacity-50':
                !hasPermission('getTelemetry') &&
                !hasPermission('getDevPoints') &&
                !hasPermission('getImplPoints') &&
                !hasPermission('getSupPoints') &&
                !hasPermission('getCommercialPerformance') &&
                !hasPermission('getCustomerQuery') &&
                // !hasPermission('getManagement') &&
                !hasPermission('getOperations') &&
                !hasPermission('getFinance') &&
                !hasPermission('getClientRevenue'),
            }"
          >
            <template #icon>
              <svg
                class="flex-shrink-0 w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 22 22 "
              >
                <path
                  d="M21 8V16C21 18.7614 18.7614 21 16 21H6C3.23858 21 1 18.7614 1 16V6C1 3.23858 3.23858 1 6 1H15"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <circle
                  cx="2"
                  cy="2"
                  r="2"
                  transform="matrix(-1 0 0 1 22 1)"
                  stroke=""
                  stroke-width="1.5"
                />
                <path
                  d="M6 13.75L9.5 9.75L12.5 12.25L16 8.25"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </template>
          </SideBarItem>
          <li
            v-if="!isMobile() || store.displayed"
            class="border-t border-surface-200 dark:border-surface-700 my-2"
          ></li>
          <!-- Company -->
          <SideBarItem
            to="/empresas/0"
            label="Empresas"
            tooltip="Empresas"
            :active="$route.path.startsWith('/empresas')"
            :is-mobile="isMobile()"
            :displayed="store.displayed"
            :class="{
              'pointer-events-none opacity-50':
                !hasPermission('getCompanies') &&
                !hasPermission('getCompanyGroups') &&
                !hasPermission('getSegment'),
            }"
          >
            <template #icon>
              <svg
                class="flex-shrink-0 w-5 h-5"
                aria-hidden="true"
                width="20"
                height="19"
                viewBox="0 0 22 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.00002 6.42871C1.00002 5.32414 1.89545 4.42871 3.00002 4.42871H19C20.1046 4.42871 21 5.32414 21 6.42871V8.08347C21 8.96669 20.4207 9.74534 19.5747 9.99913L12.1494 12.2267C11.3996 12.4516 10.6004 12.4516 9.85063 12.2267L2.42532 9.99913C1.57935 9.74534 1.00002 8.96669 1.00002 8.08347V6.42871Z"
                  stroke=""
                  stroke-width="1.5"
                />
                <path
                  d="M11 9.5717L11 7.85742"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M1.83339 9.57129L1.83339 14.9998C1.83339 17.2089 3.62425 18.9998 5.83339 18.9998H16.1667C18.3759 18.9998 20.1667 17.2089 20.1667 14.9998V9.57129"
                  stroke=""
                  stroke-width="1.5"
                />
                <path
                  d="M14.3333 4.42855V3C14.3333 1.89543 13.4379 1 12.3333 1H9.66666C8.56209 1 7.66666 1.89543 7.66666 3L7.66666 4.42855"
                  stroke=""
                  stroke-width="1.5"
                />
              </svg>
            </template>
          </SideBarItem>

          <!-- Users -->
          <SideBarItem
            to="/usuarios/0"
            label="Usuários"
            tooltip="Usuários"
            :active="$route.path.startsWith('/usuarios')"
            :is-mobile="isMobile()"
            :displayed="store.displayed"
            :class="{
              'pointer-events-none opacity-50':
                !hasPermission('getUser') &&
                !hasPermission('getDepartment') &&
                !hasPermission('getRoles'),
            }"
          >
            <template #icon>
              <svg
                class="flex-shrink-0 w-5 h-5"
                aria-hidden="true"
                width="22"
                height="17"
                viewBox="0 0 22 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="3.40426"
                  cy="3.40426"
                  r="3.40426"
                  transform="matrix(-1 0 0 1 14.617 1)"
                  stroke=""
                  stroke-width="1.5"
                />
                <path
                  d="M5.25533 12.8594C5.25533 12.1272 5.71563 11.474 6.40519 11.2277V11.2277C9.51408 10.1174 12.9115 10.1174 16.0204 11.2277V11.2277C16.7099 11.474 17.1702 12.1272 17.1702 12.8594V13.979C17.1702 14.9896 16.2751 15.7659 15.2747 15.6229L14.9412 15.5753C12.4681 15.222 9.95742 15.222 7.48437 15.5753L7.15082 15.6229C6.15039 15.7659 5.25533 14.9896 5.25533 13.979V12.8594Z"
                  stroke=""
                  stroke-width="1.5"
                />
                <path
                  d="M16.3192 7.90277C17.7964 7.90277 18.9939 6.70523 18.9939 5.22799C18.9939 3.75076 17.7964 2.55322 16.3192 2.55322"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <path
                  d="M19.2486 14.0051L19.5107 14.0425C20.2967 14.1548 21 13.5448 21 12.7508V11.8711C21 11.2958 20.6383 10.7826 20.0965 10.5891C19.5561 10.3961 19.0045 10.2458 18.4468 10.1382"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <path
                  d="M5.68086 7.90277C4.20362 7.90277 3.00609 6.70523 3.00609 5.22799C3.00609 3.75076 4.20362 2.55322 5.68086 2.55322"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <path
                  d="M2.7514 14.0051L2.48932 14.0425C1.70327 14.1548 1 13.5448 1 12.7508V11.8711C1 11.2958 1.36167 10.7826 1.90347 10.5891C2.44392 10.3961 2.99546 10.2458 3.55319 10.1382"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </template>
          </SideBarItem>
          <!-- Product -->
          <SideBarItem
            to="/produtos/0"
            label="Produtos"
            tooltip="Produtos"
            :active="$route.path.startsWith('/produtos')"
            :is-mobile="isMobile()"
            :displayed="store.displayed"
            :class="{
              'pointer-events-none opacity-50':
                !hasPermission('getProduct') &&
                !hasPermission('getOpusBkp') &&
                !hasPermission('getFluxusMobile'),
            }"
          >
            <template #icon>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="flex-shrink-0 w-5 h-5"
              >
                <path
                  d="M1 5.16667V16.1667C1 16.5704 1.24274 16.9345 1.61538 17.0897L11 21M1 5.16667L10.2308 1.32051C10.7231 1.11538 11.2769 1.11538 11.7692 1.32051L16 3.08333M1 5.16667L6 7.25M11 9.33333V21M11 9.33333L21 5.16667M11 9.33333L6 7.25M11 21L20.3846 17.0897C20.7573 16.9345 21 16.5704 21 16.1667V5.16667M21 5.16667L16 3.08333M6 7.25L16 3.08333"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linejoin="round"
                />
              </svg>
            </template>
          </SideBarItem>

          <li
            v-if="!isMobile() || store.displayed"
            class="border-t border-surface-200 dark:border-surface-700 my-2"
          ></li>
          <SideBarItem
            to="/ferramentas-utilitarios/fluxus"
            label="Ferramentas e Utilitários"
            tooltip="Ferramentas e Utilitários"
            :active="$route.path.startsWith('/ferramentas-utilitarios')"
            :is-mobile="isMobile()"
            :displayed="store.displayed"
            :class="{
              'pointer-events-none opacity-50':
                !hasPermission('getVersion') && !hasPermission('getSlide'),
            }"
          >
            <template #icon>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                class="flex-shrink-0 w-5 h-5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  id="Stroke 1"
                  d="M10.3301 16.593H4.02942"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Stroke 3"
                  d="M13.1405 6.90048H19.4412"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Stroke 5"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.72629 6.84638C8.72629 5.55072 7.66813 4.50012 6.36314 4.50012C5.05816 4.50012 4 5.55072 4 6.84638C4 8.14203 5.05816 9.19263 6.36314 9.19263C7.66813 9.19263 8.72629 8.14203 8.72629 6.84638Z"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Stroke 7"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20 16.5539C20 15.2582 18.9426 14.2076 17.6376 14.2076C16.3318 14.2076 15.2737 15.2582 15.2737 16.5539C15.2737 17.8496 16.3318 18.9001 17.6376 18.9001C18.9426 18.9001 20 17.8496 20 16.5539Z"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </template>
          </SideBarItem>
          <li
            v-if="!isMobile() || store.displayed"
            class="border-t border-surface-200 dark:border-surface-700 my-2"
          ></li>
          <SideBarItem
            to="/chatbot/0"
            label="Chatbot"
            tooltip="Chatbot"
            :active="$route.path.startsWith('/chatbot')"
            :is-mobile="isMobile()"
            :displayed="store.displayed"
            :icon="'pi pi-comment'"
          >
            <template #icon>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                class="flex-shrink-0 w-5 h-5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Iconly/Regular/Light/Chat">
                  <g id="Chat">
                    <path
                      id="Stroke 4"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M19.0714 19.07C16.0152 22.1265 11.4898 22.7868 7.78642 21.0741C7.23971 20.854 6.79148 20.6761 6.36537 20.6761C5.17849 20.6832 3.70117 21.834 2.93336 21.0671C2.16555 20.2992 3.31726 18.8207 3.31726 17.6267C3.31726 17.2005 3.14642 16.7603 2.92632 16.2125C1.21283 12.5097 1.87411 7.98281 4.93026 4.92733C8.8316 1.02455 15.17 1.02455 19.0714 4.92632C22.9797 8.83513 22.9727 15.1682 19.0714 19.07Z"
                      stroke=""
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      id="Stroke 11"
                      d="M15.9393 12.4131H15.9483"
                      stroke=""
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      id="Stroke 13"
                      d="M11.9303 12.4131H11.9393"
                      stroke=""
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      id="Stroke 15"
                      d="M7.92128 12.4131H7.93028"
                      stroke=""
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </g>
              </svg>
            </template>
          </SideBarItem>
          <SideBarItem
            to="/documentacao"
            label="Documentação"
            tooltip="Documentação"
            :active="$route.path.startsWith('/documentacao')"
            :is-mobile="isMobile()"
            :displayed="store.displayed"
            :icon="'pi pi-comment'"
            :class="{
              'pointer-events-none opacity-50':
                !hasPermission('getTechnicalDocs'),
            }"
          >
            <template #icon>
              <svg
                width="22"
                height="22"
                viewBox="0 0 24 20"
                class="flex-shrink-0 w-5 h-5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 2H15.5C17.9853 2 20 4.01472 20 6.5M11.1027 3.24371L10.3973 2.25629C9.83421 1.4679 8.925 1 7.95615 1H6C3.23858 1 1 3.23858 1 6V14C1 16.7614 3.23858 19 6 19H16C18.7614 19 21 16.7614 21 14V9.5C21 6.73858 18.7614 4.5 16 4.5H13.5439C12.575 4.5 11.6658 4.0321 11.1027 3.24371Z"
                  stroke=""
                  stroke-width="1.5"
                />
                <path
                  d="M11 14.5H17"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </template>
          </SideBarItem>
        </ul>
      </div>

      <div
        class="flex h-full items-end px-3 py-2 transition-all duration-300"
        :class="{
          'justify-center': isMobile(),
          hidden: !store.displayed && isMobile(),
        }"
      >
        <ul class="w-full flex flex-col">
          <SideBarItem
            to="/configuracoes/0"
            label="Configurações"
            tooltip="Configurações"
            :active="$route.path.startsWith('/configuracoes')"
            :is-mobile="isMobile()"
            :displayed="store.displayed"
            :class="{
              'pointer-events-none opacity-50': !hasPermission('getSettings'),
            }"
          >
            <template #icon>
              <svg
                class="flex-shrink-0 w-5 h-5"
                aria-hidden="true"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19.0728 6.98846L18.5022 5.99837C18.0195 5.16061 16.9498 4.8716 16.1109 5.3523V5.3523C15.7116 5.58754 15.2351 5.65428 14.7865 5.53781C14.3379 5.42133 13.9541 5.1312 13.7196 4.7314C13.5688 4.47728 13.4878 4.18784 13.4847 3.89235V3.89235C13.4983 3.4186 13.3196 2.95951 12.9892 2.61967C12.6589 2.27983 12.205 2.08818 11.7311 2.08838H10.5816C10.1172 2.08837 9.67206 2.2734 9.34452 2.60251C9.01698 2.93162 8.8341 3.37769 8.83633 3.842V3.842C8.82257 4.80066 8.04146 5.57055 7.08271 5.57045C6.78721 5.56738 6.49777 5.48634 6.24365 5.33552V5.33552C5.40474 4.85482 4.33504 5.14383 3.85235 5.98159L3.23984 6.98846C2.75773 7.82517 3.0428 8.8942 3.87752 9.37976V9.37976C4.4201 9.69301 4.75434 10.2719 4.75434 10.8984C4.75434 11.525 4.4201 12.1039 3.87752 12.4171V12.4171C3.04386 12.8994 2.75848 13.9659 3.23984 14.8V14.8L3.81879 15.7985C4.04495 16.2066 4.42441 16.5077 4.8732 16.6353C5.32199 16.7628 5.80311 16.7063 6.21009 16.4782V16.4782C6.61018 16.2447 7.08694 16.1807 7.53442 16.3005C7.98189 16.4202 8.36299 16.7138 8.593 17.1158C8.74383 17.37 8.82487 17.6594 8.82794 17.9549V17.9549C8.82794 18.9234 9.61306 19.7085 10.5816 19.7085H11.7311C12.6963 19.7085 13.4801 18.9285 13.4847 17.9633V17.9633C13.4824 17.4975 13.6665 17.0502 13.9958 16.7208C14.3252 16.3915 14.7725 16.2074 15.2383 16.2097C15.5331 16.2175 15.8213 16.2983 16.0774 16.4446V16.4446C16.9141 16.9267 17.9831 16.6416 18.4687 15.8069V15.8069L19.0728 14.8C19.3066 14.3987 19.3708 13.9207 19.2511 13.4718C19.1314 13.023 18.8377 12.6404 18.4351 12.4087V12.4087C18.0325 12.1771 17.7388 11.7945 17.6191 11.3457C17.4994 10.8968 17.5636 10.4188 17.7974 10.0174C17.9495 9.75195 18.1696 9.53182 18.4351 9.37976V9.37976C19.2648 8.89446 19.5492 7.83168 19.0728 6.99685V6.99685V6.98846Z"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <ellipse
                  class=""
                  :class="{
                    'stroke-white': $route.path.startsWith('/configuracoes'),
                  }"
                  cx="11.1606"
                  cy="10.8984"
                  rx="2.41648"
                  ry="2.41648"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </template>
          </SideBarItem>
          <SideBarItem
            to="#"
            label="Sair"
            tooltip="Sair"
            :active="false"
            :is-mobile="isMobile()"
            :displayed="store.displayed"
            @click="logoutDialog = true"
          >
            <template #icon>
              <svg
                class="flex-shrink-0 w-5 h-5"
                aria-hidden="true"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.7647 6.77369V5.91844C13.7647 4.05303 12.2522 2.54053 10.3868 2.54053H5.91802C4.05352 2.54053 2.54102 4.05303 2.54102 5.91844V16.1209C2.54102 17.9864 4.05352 19.4989 5.91802 19.4989H10.3959C12.2558 19.4989 13.7647 17.9909 13.7647 16.131V15.2666"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M19.9922 11.0194H8.95459"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17.3079 8.34766L19.9919 11.0197L17.3079 13.6927"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </template>
          </SideBarItem>
        </ul>
      </div>
      <!-- Version Info -->
      <span
        class="flex justify-center font-bold text-surface-400 text-xs mt-2 mb-10 transition-opacity duration-300"
        :class="{ hidden: !store.displayed && isMobile() }"
      >
        v{{ version }}
      </span>
    </div>
  </aside>
  <Dialog
    v-model:visible="logoutDialog"
    class="w-9/12 sm:w-1/2 lg:w-1/3 xl:w-1/4"
    header="Sair"
    :modal="true"
  >
    <div class="confirmation-content flex">
      <i class="pi pi-exclamation-triangle mr-5 text-orange-600 text-3xl" />
      <span>Deseja realmente sair?</span>
    </div>
    <template #footer>
      <Button
        label="Não"
        icon="pi pi-times"
        class="text-red-500"
        text
        @click="logoutDialog = false"
      />
      <Button label="Sim" icon="pi pi-check" text @click="logout" />
    </template>
  </Dialog>
</template>

<script>
import { version } from "@/config/version";
import { useStore } from "@/store";
import { clearLocalStorageExcept } from "@/utils/storageUtils";
import SideBarItem from "./SideBarItem.vue";

export default {
  components: {
    SideBarItem,
  },
  data() {
    return {
      logoutDialog: false,
      pathLogin: false,
      version: version,
    };
  },

  emits: ["showSidebar"],
  computed: {
    store() {
      return useStore();
    },
  },
  mounted() {
    this.pathLogin = !!localStorage.getItem("backoffice_token");
    if (this.pathLogin == true) {
      this.$emit("showSidebar");
    }
  },
  methods: {
    toggleSidebar() {
      this.store.toggleSidebarVisibility();
      this.$emit("showSidebar");
    },
    isMobile() {
      return window.innerWidth < 640;
    },
    hasPermission(permission) {
      return this.$permissions.hasPermission(permission);
    },
    logout() {
      clearLocalStorageExcept();
      this.$router.push("/login");
    },
  },
};
</script>
